
.tabel-operation{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .tabel-operation .left-section{
    display: flex;
  }
  .tabel-operation .button-add{
    margin: 10px;
    font-size: 14px;
    background-color: #FFC107;
    color: black;
    border-color: transparent;
    margin-left: auto;
    margin-right: 10px;
    border-radius: 15px;
    width: 100px;
    padding: 0;
  }
   .tabel-operation .btn-search {
    padding: 2px;
    height: 35px;
    width: 30px;
    border-color: transparent;
    background-color: #00B4CC;
      color:white;
  }   
  .tabel-operation input{
  
    border: 1px solid #00B4CC;;
    height: 35px;
    width: 300px;
    padding: 5px;
  }
  .tabel-operation .textarea:focus, input:focus{
    outline: none;
  }
  .tabel-operation .export{
    margin-left: 40px;
  }
  .tabel-operation .export Button{
    font-size: 12px;
    /* width: 50px; */
    height: 35px;
  }
  .tabel-operation .slection-box{
   padding-left: 10px;
   padding-right: 10px;
    margin-left: 20px;
    border-radius: 10px;
  }
  

  
  