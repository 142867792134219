.Cutso-Form{
    display: flex;
  align-items: flex-start;
    border: 1px;
    padding: 20px;
    box-shadow: 1px 1px 1px #999;

}
.Cutso-Form .form-section{
    width: 500px;

}
.Cutso-Form .form-section-update{
    width: 50%;
}
.Cutso-Form .image-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 70px;
}
.Cutso-Form .image-box-add{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 200px;
    width: 200px;
    border:.5px solid rgb(201, 200, 200);
    border-radius: 10%;
    background-color: rgb(250, 247, 247);
}

.Cutso-Form img{
    height: 200px;
    width: 200px;
}
/* .AddSchool .image-box input{
    visibility:hidden */
/* } */
.Cutso-Form .image-operation-btn{
    color: black;
    background-color: white;
        font-size: 12PX;
    padding: 10px;
    border-radius: 10%;
    border: 1px;
    margin-top: 10PX;
}
.Cutso-Form .image-operation-btn:hover{
    background-color:rgb(241, 239, 239);
}


.Cutso-Form .dropdown{
color:black;
    background-color: white;

    
}
.Cutso-Form .form-label{
    font-size: 14px;
    font-weight: 500;
}
.Cutso-Form .form-control
{
    font-size: 14px;
}
.Cutso-Form .dropdown{
    font-size: 14px; 
}
.form-card-body{
    padding-left: 5%;
    padding-right: 5%;
}
.Cutso-Form .form-check {
    margin-bottom: 10px;
}

.Modal-Form .btn{
    margin-right: 10px;
}
.error-text{
    padding-top: 5px;
    color: red;
}

