.UpdateBus .sync-card{
    background-color: #2C2E3E;
    color: white;

} 
.UpdateBus thead{
    background-color:  rgb(205, 201, 201) !important;
    font-size: 14px;
    color: black;

  }