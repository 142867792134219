.ImportStudentData .spacer-2x{
    padding-top: 40px;
}
.ImportStudentData .spacer-right-2x{
    padding-left: 20px;
}

.ImportStudentData  .image-operation-btn{
    color: rgb(214, 49, 49);
    background-color: white;
        font-size: 15PX;
    padding: 10px;
    border-radius: 10%;
    border: 1px;
    margin-top: 10PX;
}
.ImportStudentData  .image-operation-btn:hover{
    background-color:rgb(241, 239, 239);
}
.ImportStudentData .selectedFileName {
    color: rgb(69, 32, 218);
    font-weight: 500;
}
.ImportStudentData .close-btn {

    color: rgb(235, 27, 27);
    font-size: 40px;
    font-weight: 600;
}
.ImportStudentData .close-btn {/* Adjust this value for vertical alignment */
    cursor: pointer;
  }
  

