.AdminProfile {
    display: flex;
    align-items: center;
    justify-content: center;
}

.AdminProfile img {
    border: 1px solid;
    height: 70px;
    width: 70px;
      border-radius: 50%;

    }
    .AdminProfile .admin-name{
        display: flex;
        flex-direction: column;
        margin-left: 10px;

    }
    .AdminProfile p{
        margin: 0;
        color: white;
    }
    .AdminProfile p{
        margin: 0;
        color: white;
    }
    .AdminProfile .small-text{
        font-size: 12px;
    }
   