
.GoogleMapLiveBusTrack .row-section{
    display: flex;
    justify-content: center;
    align-items: center;
    
      }
      .GoogleMapLiveBusTrack .map-section{
        flex: 4;
    
      }
      .GoogleMapLiveBusTrack .list-section{
        flex: 1;
        max-height: 500px;
        min-height: 500px;
        overflow: auto;
        border: 1px solid rgb(216, 216, 216)
        
      }
      .GoogleMapLiveBusTrack .list-item{
       padding-bottom: 20px;
       border-bottom: 1px solid rgb(216, 216, 216)
    
    }
      .GoogleMapLiveBusTrack .space-box{
        height: 5px;
      }
      .GoogleMapLiveBusTrack .space-button{
        padding: 10px;
      }
      