
.body-table{
    box-shadow: 1px 1px 1px #999;
  }
  .body-table thead{
    background-color: #2C2E3E;
    font-size: 14px;
    color: white;
  }
  .body-table-action .btn{
    border-color: transparent;
    margin-left: auto;
    margin-right: 10px;
    border-radius: 15px;
    width: 40px;
    height: 30px;
    padding: 0;

  }
  .body-table .btn{
    border-color: transparent;
    margin-left: auto;
    margin-right: 10px;
    border-radius: 15px;
    width: 40px;
    height: 30px;
    padding: 0;

  }
  .body-table-action .fa{
    font-size: 12px;
    color: white;
  }
  .body-table .fa{
    font-size: 12px;
    color: white;
  
  }
  .body-table-action .btn-edit{
    background-color: #28A745;
  }
  .body-table .btn-edit{
    background-color: #28A745;
  }
  .body-table-action .btn-delete{
    background-color: #DC3545;
  }
  .body-table .btn-delete{
    background-color: #DC3545;
  }
  .body-table tbody{
    vertical-align: middle;
    font-size: 13px;
  }
  .alert-box-title-error{
    color: red;
    font-size: 20px;
  }
  .alert-box-title{
    color: green;
    font-size: 20px;

  }
  .alert-box-body{
    font-size: 16px;
  }
  .alert-box-btn{
    margin-right: 10px;
   
  }
