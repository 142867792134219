.login-page{
    background-image: url("../../assets/bg6cc.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    display: flex;
    background-color: rgb(245,245,245);
}

.login-page .herader-divider{
    margin-bottom: 20px;
    margin-top: 20px;
    border: 1px solid rgb(210, 207, 207);

}
.login-page .header-name-logo h2{
    color: rgb(249, 249, 249);


}
.login-page .header-name-logo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80px;
    margin-bottom: 10px;
    


}
.login-page .card-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.login-page .header-logo-image{
    height: 100px;
    margin-right: 20px;

}
.login-page .card-header-logo-image{
    height: 50px;
    margin-left: 20px;
}

.login-page .form-card{
    border: 1px solid whitesmoke;
    padding: 40px;
    border-radius: 25px;
    width: 40%;
    background-color:white
 
}
.login-page .resMsg{
    margin-top: 10px;
    color: red;

}