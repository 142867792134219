
.Header{
    display: flex;
  justify-content: space-between;
  align-items: center;
}
.Header .title{
    font-size: 25px;
    font-weight: 600;
    color: #11283F;
}
.Header ul{
    display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header ul li{
    margin: 10px;
    width: 30px;
    height: 30px;
    line-height: 25px;
    text-align: center;
list-style: none;
 border: 1px solid #2e4ead;
 border-radius: 100%;
 color: #2e4ead;
}


