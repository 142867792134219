

.sideNavBar{
    height: 100%;
    width: 15%;

    background-color: #11283F;
        position: fixed;
        overflow: auto;

}
.sideNavBar ul{
    padding: 0;
}


.sideNavBar ul li{
    list-style: none;
    height: 50px;
    display: flex;
    align-items: center;
    color: #adbbc3;
    margin-bottom: 15px;
}

.sideNavBar ul li span.icon{
   padding: 10px;
}
.sideNavBar ul li span.title{
    font-size: 15px;
    font-weight: 500;
}
.sideNavBar ul li:hover{
    /* background-color: #173757; */
    background-color: white;
    color: #173757;
    /* border-radius:100% 0% 0% 100%; */
    
}
.sideNavBar  .currentPath{
  
       background-color: white;
       color: #173757;
 
}

