
.App{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: white;
 
}


.App .left-body{
   width: 15%;
  height: 100vh;
}
.App .right-body{
  padding-left: 20px;
  padding-right: 20px;
  width: 85%;
  height: 100vh;
}

.toast-success-container {
  color: #000 !important;
  border-radius: 8px !important;
  background: #FFFFFF !important;
  border: 1px solid #34A853 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-success-container-after {
  overflow: hidden;
  position: relative;
}

.toast-success-container-after::after{
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #34A853;
}
