.dashboard{
    background-color: whitesmoke;
    height: 100%;
    width: 100%;
    padding: 1%;
}
.dashboard  .item-count-box{
    display: flex;
    flex-wrap: wrap;


}
.dashboard .info-box-card{
    background-color: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    height: 10%;
    width: 24%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    border-radius: 5%;
    margin-right: 1%;
    margin-bottom: 10px;
    padding: 10px;

}
.dashboard .info-box-card .image-section{
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    /* background-color: red; */
 

}
.dashboard .info-box-card .image-section .image{
    padding: 10px;
    height: 40px;
    width: 40px;
    border-radius: 10%;
    color: white;
    background-color: #2C2E3E;
    display: flex;
    align-items: center;
    justify-content: center;

}
.dashboard .info-box-card .info-section{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
   font-weight: 600;
}
.dashboard .first-chart-section{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: true;
}
.dashboard .empChartBox{
     height: 330px;
   /* width: 500px; */
    display: flex;
    margin: 10px;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color:white;
     box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.dashboard .first-line-chart{
 width: 100%;

}


