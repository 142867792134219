.UpdateTrip .sync-card{
  background-color: #2C2E3E;
  color: white;

} 
.UpdateTrip thead{
  background-color:  rgb(205, 201, 201) !important;
  font-size: 14px;
  color: black;

}
.TripTraveler{

    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
     /* border-radius: 1%; */
    box-shadow: 1px 1px 1px 1px rgb(223, 221, 221); 
}
.TripTraveler .first-section{
  margin-bottom: 50px;
  display: flex;
  width: 100%;

  
}
.TripTraveler .first-section .center-box{
width: 2%;

}

.TripTraveler .second-section{
  display: flex;
  align-items: stretch;
}


.TripTraveler .add-traveler{
    flex: 70%;
  
}
.TripTraveler .user-list{
    flex: 30%;
  
}
.TripTraveler .list-group{
    max-height: 100vh;
    min-height: 30vh;
    overflow: auto;
}


  .TripTraveler .traveler-header{
    padding-bottom: 10px;
    /* background-color: rgb(250, 250, 250);
    box-shadow: 1px 1px 1px #999; */
    font-size: 20px;
    font-weight: 500;
    text-align: center;

  }
  .TripTraveler .traveler-header .header-btn{
    margin-left: 10px;
    margin-right: 10px;
    background-color: #999;
    
  }
  .TripTraveler .traveler-header .selected-btn{
    background-color: green;
  }

.TripTraveler .traveler-heade .update-btn{
  background-color: blue;
  margin-left: 10px;
  margin-right: 10px;
}
.TripTraveler .traveler-header .op-section{
  padding: 8px
}
.TripTraveler .filter-section{
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TripTraveler .filter-section .check-box{
  padding: 3px;
  font: .8em sans-serif;
}
  .TripEmp .tabel-option{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;

  }
  .TripEmp .add-emp-label{
    min-width: 150px;
  }
   .google-map-picker-box{
    display: flex;
    justify-content: center;
    align-items: flex-start;


  }
 .map-modal{
  max-width: none !important;
    width: 90vw !important;
  
  }
   .TripDetails .init-btn{
    margin-left: 10px;
    margin-bottom: 10px;
  
    
  }

  .search-place-box{
    width: 20%;    
  }
 .form-modal{
  max-width: none !important;
    width: 70vw !important;
    
  }
   .verical-divider{
    display: flex;
    justify-content: center;
    width: 100% !important;
    height: 100%;

  }
  .verical-divider .line{
  display: block;
  width: 1px;
  height: 100%;
  background-color: rgb(236, 234, 234);
}
.map-btn{
  font-size: 12px;
}

.user-box-TripDetails{
  border: 2px solid rgb(217, 217, 217);
  border-radius: 10px;
  display: flex;
  padding: 10px;
  margin: 5px;
  /* width: 30vw !important; */

}

.user-box-TripDetails .description{
  margin-left: 10px;
display: flex;
flex-direction: column;
}
.selectable-TripDetails :hover {
  background-color: rgb(226, 247, 207);
  cursor:pointer
}
.selected-user-TripDetails{
  background-color: rgb(226, 247, 207);
}
.user-box-TripDetails .status-box{
  background-color: red;
}
.user-box-TripDetails .status-box-orange{
  background-color: whitesmoke;
  padding: 3px;
  color: rgb(189, 145, 13);
}
.user-box-TripDetails .status-box-green{
  background-color: whitesmoke;
  padding: 3px;
  color: green
}
.UpdateTripHistory .trip-status-box
{
  display: flex;
  padding: 10px;
  background-color:whitesmoke;
  font-size: 18px;
  color: black;
  

}
.UpdateTripHistory .dot
{
  height: 14px;
  width: 14px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin: 5px;
  margin-right: 10px;

}
