.HeaderSubTitle{
    display: flex;
    align-items: center;
    border-top: 1px solid rgb(233, 232, 232);
    border-bottom: 1px solid rgb(233, 232, 232);
    padding-top: 10px;
    padding-bottom: 10px;

}
.HeaderSubTitle span{
    padding: 5px;
}
.HeaderSubTitle .title{
    font-size: 22px;
}
.HeaderSubTitle i{
    color: gray;
}